import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonType } from '../../../components/shared/form-control/Button';
import { Heading, HeadingSize } from '../../../components/shared/text/Heading';
import { FlowProps } from './RegisterFlow';
import { ModalContext } from '../../../contexts/ModalContext';
import ConfirmationModal from '../../../components/shared/modal/variants/ConfirmationModal';

const TwoFAInfo: FC<FlowProps> = (props) => {
  const { next } = props;
  const [modalOpen, setModalOpen] = useState(true); // Open upon landing

  const { t } = useTranslation('auth');

  return (
    <>
      <Heading size={HeadingSize.H1}>{t('registration.2fa-info.heading')}</Heading>
      <div className="w-3/4 text-center">
        <p className="my-4 font-medium">{t('registration.2fa-info.subheading-1')}</p>
        <p className="leading-7">{t('registration.2fa-info.subheading')}</p>
      </div>
      <div className="my-4 cursor-pointer font-medium hover:underline" onClick={() => setModalOpen(true)} data-cy="register-2fa-info-trigger-modal">
        {t('registration.2fa-info.trigger-modal')}
      </div>
      <div className="w-128 text-center">
        <div className="bg-accent-light-mid my-4 p-6">
          <div className="pb-4 font-medium">{t('registration.2fa-info.apps.heading')}</div>
          <div className="ml-4">
            <div>{t('registration.2fa-info.apps.google-authenticator')}</div>
            <div>{t('registration.2fa-info.apps.microsoft-authenticator')}</div>
            <div>{t('registration.2fa-info.apps.free-otp')}</div>
            <div>{t('registration.2fa-info.apps.authy')}</div>
          </div>
        </div>

        <div className="px-10 text-center">
          <Button className="mt-6 w-full" type={ButtonType.PRIMARY} onClick={next} data-cy="register-2fa-info-continue">
            <span className="font-medium">{t('registration.2fa-info.buttons.continue')}</span>
          </Button>
        </div>
      </div>

      <ModalContext.Provider value={{ open: modalOpen, modalWidth: 'w-2/5', onClose: () => setModalOpen(false) }}>
        <ConfirmationModal
          title={t('registration.2fa-info.modal.heading')}
          description={
            <>
              <div className="my-2">{t('registration.2fa-info.modal.paragraph1')}</div>
              <div className="my-2">{t('registration.2fa-info.modal.paragraph2')}</div>
            </>
          }
          confirmText={t('registration.2fa-info.modal.buttons.done')}
          onConfirm={() => setModalOpen(false)}
        />
      </ModalContext.Provider>
    </>
  );
};

export default TwoFAInfo;
